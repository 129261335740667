.btn {
	font-family: $font-button1;
	font-size: 1.0em;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	border-radius: 0;
	padding: 0.75em 2.25em;
	margin: 0.2em 0.1em;
	text-decoration: none !important;
	-webkit-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-moz-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-ms-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-o-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	&:hover {
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		text-decoration: none;
	}
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0  !important;
		background-image: none  !important;
		// -webkit-box-shadow: 0 0 8px rgba($brandcolor1-light, 0.8) !important;
		// -moz-box-shadow: 0 0 8px rgba($brandcolor1-light, 0.8) !important;
		// box-shadow: 0 0 8px rgba($brandcolor1-light, 0.8)  !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none  !important;
	}
}

.btn-sm {
	font-size: 0.875em;
	padding: 0.5625em 1.5625em;
}

.btn-lg {
	font-size: 1.25em;
	padding: .75em 3.0em;
}

.btn-primary {
	background-color: $brandcolor1-light;
	border: 2px solid $brandcolor1-light;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light2;
		border: 2px solid $brandcolor1-light2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: 2px solid $brandcolor1;
	}
}

.btn-secondary {
	background-color: $gray-mid2;
	border: 2px solid $gray-mid2;
	&:hover {
		color: $white;
		background-color: $gray-dark1;
		border: 2px solid $gray-dark1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $gray-light3;
		border: 2px solid $gray-light3;
	}
}

.btn-outline-primary {
	background-color: none;
	color: $brandcolor1-light;
	border: 2px solid $brandcolor1-light;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light2;
		border: 2px solid $brandcolor1-light2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: 2px solid $brandcolor1;
	}
}

.btn-outline-secondary {
	color: $gray-dark1;
	border: 2px solid $gray-mid2;
	&:hover {
		color: $white;
		background-color: $gray-dark1;
		border: 2px solid $gray-dark1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $gray-light3;
		border: 2px solid $gray-light3;
	}
}

.btn-dark {
	background-color: $black;
	border: 2px solid $black;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light2;
		border: 2px solid $brandcolor1-light2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: 2px solid $brandcolor1;
	}
}

.btn-light {
	background-color: $white;
	border: 2px solid $white;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light2;
		border: 2px solid $brandcolor1-light2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: 2px solid $brandcolor1;
	}
}

.btn-outline-dark {
	background-color: none;
	color: $black;
	border: 2px solid $black;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light2;
		border: 2px solid $brandcolor1-light2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: 2px solid $brandcolor1;
	}
}

.btn-outline-light {
	background-color: none;
	color: $white;
	border: 2px solid $white;
	&:hover {
		color: $white;
		background-color: $brandcolor1-light2;
		border: 2px solid $brandcolor1-light2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: 2px solid $brandcolor1;
	}
	&.btn-sm {
		font-weight: 400;
		border-width: 1px !important;
	}
}

.btn-link {
	color: $brandcolor1-light;
	text-decoration: none;
	border: none;
	&:hover {
		color: $brandcolor1-light2;
		text-decoration: underline;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1;
		text-decoration: underline;
		border: none;
	}
}
