.entry-pages-contact-us {
	.page-title-container {
		padding-bottom: 3.125em;
	}
	.contact-all-container {
		padding: 2.5rem 1.0rem 0 1.0rem;
		.contact-form-container {
			padding: 0.625rem 2.5rem 2.0em 2.5rem;
			h3 {
				margin-bottom: 0;
			}
		}
		.contact-info-container {
			padding: 3.75em 2.5rem 0 2.5rem;
			@media (max-width: 991.9px) {
				text-align: center;
			}	
			.contact-info-group {
				margin-bottom: 3.75rem;
			}
			h5 {
			}
			p {
				font-size: 1.125rem;
				line-height: 1.2;
				b, strong {
					font-weight: 400;
					color: $black;
				}
			}
			a {
				color: $gray-dark1;
				text-decoration: none;
				&:hover {
					color: $brandcolor1;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
					text-decoration: none;
				}
			}
		}
	}
}