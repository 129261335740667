.hosted-video-cover {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	//min-width: 100%;
	//min-height: 100%;
	width: 100%;
	height: auto;
	@media (max-aspect-ratio: 24/17) {
		width: auto;
		height: 100%;
	}
}