.link-text-nav, .link-text-subnav {
	font-family: $font-nav1;
	font-weight: 700;
	font-size: clamp(0.5625rem, -webkit-calc(0.0rem + 0.5625 * ((100vw - 36rem) / 39)), 0.6875rem);
	font-size: clamp(0.5625rem, -moz-calc(0.0rem + 0.5625 * ((100vw - 36rem) / 39)), 0.6875rem);
	font-size: clamp(0.5625rem, calc(0.0rem + 0.5625 * ((100vw - 36rem) / 39)), 0.6875rem);
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: clamp(0.001rem, -webkit-calc(0.0rem + 0.165 * ((100vw - 36rem) / 39)), 0.166rem);
	letter-spacing: clamp(0.001rem, -moz-calc(0.0rem + 0.165 * ((100vw - 36rem) / 39)), 0.166rem);
	letter-spacing: clamp(0.001rem, calc(0.0rem + 0.165 * ((100vw - 36rem) / 39)), 0.166rem);
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $brandcolor1-light2;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1 !important;
		text-decoration: none;
	}
	.fa, .fac {
		color: $brandcolor1-light;
	}
}

.link-text-subnav {
	color: $gray-mid2;
	font-family: $font-nav1;
	font-weight: 700;
	font-size: clamp(0.5rem, calc(0.5rem + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
	font-size: clamp(0.5rem, -webkit-calc(0.5rem + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
	font-size: clamp(0.5rem, -moz-calc(0.5rem + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
	.fa, .fac {
		font-size: 0.8em;
		transform: translateY(-0.125em);
		color: $gray-mid2;
		&:hover {
			color: $brandcolor1-light;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1;
		}
	}
}

.link-text-sm {
	color: $brandcolor1;
	font-family: $font-nav1;
	font-weight: 700;
	font-size: clamp(0.5rem, calc(0.5rem + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
	font-size: clamp(0.5rem, -webkit-calc(0.5rem + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
	font-size: clamp(0.5rem, -moz-calc(0.5rem + 0.25 * ((100vw - 36em) / 39)), 0.75rem);
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.166rem;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1-dark;
		}
	}
	.fa, .fac {
		font-size: 0.8em;
		transform: translateX(0.25em);
	}
}

.nav-tabs {
	margin: 2.5em 0 0 0 !important;
	padding: 0 !important;
	position: relative;
	z-index: 50;
	width: 100%;
	background-color: $white !important;
	border-top: 1px solid $brandcolor2;
	border-bottom: 1px solid $white;
	li {
		//border-bottom: 1px solid $brandcolor2;
		margin: 0 !important;
		padding: 0 !important;
		&::marker {
			content: "" !important;
			display: none !important;
		}
		.nav-link {
			color: $brandcolor1;
			margin: 0 !important;
			padding: 0.625em 0.625em !important;
			&:hover {
				color: $brandcolor1-dark;
				border-radius: 0;
				border: 1px solid $white !important;
				margin: 0 !important;
				padding: 0.625em !important;
			}
		}
		.active {
			background: linear-gradient(180deg, rgba($gray-white2,1) 0%, rgba($gray-white2,0) 100%);
			border-radius: 0 !important;
			border-left: 1px solid $brandcolor2 !important;
			border-right: 1px solid $brandcolor2 !important;
			border-bottom: 1px solid $white !important;
			border-top: 1px solid $gray-light1 !important;
			&:hover {
				background-color: $white !important;
				border-radius: 0 !important;
				border-left: 1px solid $brandcolor2 !important;
				border-right: 1px solid $brandcolor2 !important;
				border-bottom: 1px solid $white !important;
				border-top: 1px solid $white !important;
			}
		}
	}
}

.categories-sticky-container {
	padding: 1.25rem 0;
	z-index: 2;
	@media (max-width: 991.9px) {
		padding: 0;
	}
}

.nav-sub-inline {
	@media (max-width: 575.9px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	.list-inline-item {
		margin: 0 0.625rem;
		@media (max-width: 575.9px) {
			display: block;
			margin: 0;
			padding: 1.0rem 0;
			width: 100%;
			text-align: center;
			&:not(:last-child) {
				border-bottom: 1px solid $gray-white1;
			}
		}
	}
}

.btn-nav-accordion-button {
	margin: 0;
	padding: 1.25rem 0 !important;
	border-radius: 0;
	border-bottom: 1px solid $brandcolor3;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0 !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	&[aria-expanded="true"] {
		.card-title {
			.fac { transform: rotate(180deg); }
		}
	}
	@media (max-width: 575.9px) {
		// margin-bottom: 1.25rem;
	}
	.fac {
		display: inline-block;
		margin-right: 0.3125em;
		line-height: 1;
		transition: transform 300ms ease-in-out;
		transform: rotate(0deg);
		color: $brandcolor1-light;
	}
	.category-nav-text {
		@extend .link-text-subnav;
		display: inline-block;
		margin: 0;
		padding: 0;
		color: $brandcolor2-light;
		font-size: clamp(0.875rem, calc(0.875rem + 0.25 * ((100vw - 36em) / 39)), 1.125rem);
		font-size: clamp(0.875rem, -webkit-calc(0.875rem + 0.25 * ((100vw - 36em) / 39)), 1.125rem);
		font-size: clamp(0.875rem, -moz-calc(0.875rem + 0.25 * ((100vw - 36em) / 39)), 1.125rem);
		line-height: 1;
	}
}

header {
	nav {
		width: 100%;
		.navbar-collapse {
			.navbar-nav {
				margin: 0;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				@include media-breakpoint-up(xl) {
					li:hover>.dropdown-menu {
						display: block;
					}
				}
				.nav-link, .dropdown-item {
					color: $brandcolor2-light2;
					-webkit-transition: all 0.5s ease-out;
					-moz-transition: all 0.5s ease-out;
					-ms-transition: all 0.5s ease-out;
					-o-transition: all 0.5s ease-out;
					transition: all .5s ease-out;
					&:hover {
						color: $white;
						background-color: $brandcolor1;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white !important;
						text-decoration: none;
					}
				}
				.nav-item {
					margin: 0;
					position: relative;
					.nav-link {
						position: relative;
						margin: 0;
						padding: 1.25rem 0.5rem;
						padding: clamp(0.5rem, -webkit-calc(0rem + 0.5 * ((100vw - 36rem) / 39)), 1.25rem);
						padding: clamp(0.5rem, -moz-calc(0rem + 0.5 * ((100vw - 36rem) / 39)), 1.25rem);
						padding: clamp(0.5rem, calc(0rem + 0.5 * ((100vw - 36rem) / 39)), 1.25rem);
						// font-size: clamp(0.875em, calc(0.875em + 0.125 * ((100vw - 36em) / 39)), 1.0em);
						// font-size: clamp(0.875em, -webkit-calc(0.875em + 0.125 * ((100vw - 36em) / 39)), 1.0em);
						// font-size: clamp(0.875em, -moz-calc(0.875em + 0.125 * ((100vw - 36em) / 39)), 1.0em);
						padding-top: 1.25rem;
						padding-bottom: 1.25rem;
						white-space: nowrap;
					}
					.dropdown-toggle {
						margin-bottom: 0 !important;
						z-index: 10;
						&:hover {
							&::after {
								color: $brandcolor3;
								opacity: 1;
								// transform: rotate(180deg);
							}
							@include media-breakpoint-up(xl) {
								.dropdown-menu {
									display: block;
									margin-top: 0; // remove the gap so it doesn't close
								}
							}
						}
						&[aria-expanded="true"] {
							&::after { transform: rotate(180deg); }
						}
						&::after {
							content: "\f004";
							font-family: $font-icons2;
							display: none;
							font-weight: normal;
							font-style: normal;
							font-size: 0.6875rem;
							line-height: 0;
							text-rendering: auto;
							border: none;
							opacity: 0.5;
							transform: translateY(0.125rem);
							-webkit-transition: transform 300ms ease-in-out;
							-moz-transition: transform 300ms ease-in-out;
							-ms-transition: transform 300ms ease-in-out;
							-o-transition: transform 300ms ease-in-out;
							transition: transform 300ms ease-in-out;
						}
						&.dropdown-item {
							&::after {
								content: "\f005";
								float: right;
								transform: translateY(0.5rem);
							}
						}
						&.dropdown-toggle-mobile {
							z-index: 20;
							position: absolute;
							top: 0;
							right: 0;
							margin-left: 0;
							margin: 0 !important;
							border-left: 1px solid $brandcolor1-light;
							&::after {
								display: inline-block;
							}
						}
						@include media-breakpoint-up(xl) {
							&::after {
								display: inline-block;
							}
						}
					}
					.dropdown-menu {
						margin: 0;
						padding: 0;
						border-radius: 0;
						border: none;
						background: none;
						@include media-breakpoint-up(xl) {
							&::before {
								content: "";
								position: absolute;
								display: block;
								top: 0;
								bottom: 0;
								left: 0;
								right: 0;
								z-index: -1;
								background-color: rgba($brandcolor1-dark, 0.9);
								-webkit-backdrop-filter: saturate(180%) blur(8px);
								-moz-backdrop-filter: saturate(180%) blur(8px);
								-ms-backdrop-filter: saturate(180%) blur(8px);
								-o-backdrop-filter: saturate(180%) blur(8px);
								backdrop-filter: saturate(180%) blur(8px);
							}
							/* Fallback when backdrop-filter not supported */
							@supports (not (backdrop-filter: blur(8px))) and (not (-webkit-backdrop-filter: blur(8px))) and (not (-moz-backdrop-filter: blur(8px))) and (not (-ms-backdrop-filter: blur(8px))) and (not (-o-backdrop-filter: blur(8px))) {
								background-color: rgba($brandcolor1-dark, 0.95);
							}
							box-shadow:
								0px 2.8px 2.2px rgba($brandcolor1-dark, 0.014),
								0px 6.7px 5.3px rgba($brandcolor1-dark, 0.02),
								0px 12.5px 10px rgba($brandcolor1-dark, 0.025),
								0px 22.3px 17.9px rgba($brandcolor1-dark, 0.03),
								0px 41.8px 33.4px rgba($brandcolor1-dark, 0.036),
								0px 100px 80px rgba($brandcolor1-dark, 0.05);
							.nav-item {
								> li:hover {
									> .submenu {
										display: block;
									}
								}
							}
						}
						.dropdown-item {
							margin: 0 !important;
							padding: 0.75em !important;
							width: auto;
							font-size: clamp(0.875rem, calc(0.875rem + 0.125 * ((100vw - 62rem) / 13)), 1.0rem);
							font-size: clamp(0.875rem, -webkit-calc(0.875rem + 0.125 * ((100vw - 62rem) / 13)), 1.0rem);
							font-size: clamp(0.875rem, -moz-calc(0.875rem + 0.125 * ((100vw - 62rem) / 13)), 1.0rem);
							font-weight: 400;
							text-transform: none;
							letter-spacing: 0;
							border-bottom: 1px solid $brandcolor1-light !important;
							&:last-child {
								border-bottom: none;
							}
							&.active {
								background: none;
							}
							&:hover {
								background-color: $brandcolor1;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								background: none;
							}
						}
					}
					.submenu {
						@include media-breakpoint-up(xl) {
							display: none;
							position: absolute;
							top: 0;
							left: 100%;
							right: auto;
							&.submenu-left {
								right: 100%;
								left: auto;
							}
						}
					}
				}
			}
		}
	}
	.navbar-toggler {
		//margin-left: auto;
		margin-right: auto;
		padding: 1.25rem 1.875rem;
		font-family: $font-nav1;
		font-weight: 700;
		font-size: 0.6875rem;
		letter-spacing: 0.166rem;
		line-height: 1;
		text-transform: uppercase;
		color: $brandcolor2-light2;
		.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
			outline: 0 !important;
			outline-offset: 0  !important;
			background-image: none  !important;
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none  !important;
		}
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(200,219,255,193)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

@media (max-width: 1199.9px) {
	.navbar-expand-xl {
		.navbar-collapse {
			position: absolute;
			top: 3.625rem;
			left: 0;
			z-index: 200;
			width: auto;
			margin: 0 !important;
			padding: 0 !important;
			background-color: $brandcolor1 !important;
			.navbar-nav {
				border: none;
				float: none;
				.nav-link, a.dropdown-item, .link-text-nav {
					margin: 0;
					padding: 1.125rem 1.875rem !important;
					font-size: 0.6875rem;
					// font-size: clamp(1.125rem, calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					// font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					// font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					letter-spacing: 0.166rem;
					text-align: left;
				}
				.nav-item {
					margin: 0;
					width: 100%;
					&:not(:last-child) {
						border-bottom: 1px solid $brandcolor1-light !important;
					}
					.nav-link {
						padding: 1.125rem 1.875rem !important;
						border-bottom: none;
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
						&.active {
							border-bottom: none;
						}
					}
					.dropdown-toggle {
						&:not(.dropdown-toggle-mobile) {
							/* Standard dropdown-toggle padding (padding x1), plus left/right padding of dropdown-toggle (padding x2), plus width of arrow (font size x1.375) */
							padding-right: calc(1.875rem * 3 + 0.6875rem * 1.375) !important;
						}
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
						&.dropdown-item {
							&[aria-expanded="true"] {
								&::after { transform: rotate(180deg); }
							}
							&::after {
								content: "\f004";
								margin-left: 0.3125rem;
								float: none;
								transform: translateY(0.125rem);
								font-size: 0.6875rem;
							}
						}
					}
					.dropdown-menu {
						padding: 0;
						transition-duration: 0s;
						border-top: 1px solid $brandcolor1-light !important;
						&.submenu {
							position: relative;
							left: 0;
							top: 0;
							&.submenu-left, &.submenu-right {
								left: 0;
								right: auto;
								a.dropdown-item, a.dropdown-toggle {
									background-color: $brandcolor1-dark2;
								}
							}
						}
						a.dropdown-item, a.dropdown-toggle {
							margin: 0;
							padding: 1.125rem 1.875rem !important;
							font-size: 1.0rem;
							// font-size: clamp(1.125rem, calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
							// font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
							// font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
							text-align: left;
							background-color: $brandcolor1-dark;
							border-bottom: none !important;
							&:last-child {
								border-bottom: none !important;
							}
							&:hover {
								background-color: $brandcolor1;
								border-bottom: none;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								// background-color: $brandcolor1;
								border-bottom: none;
							}
							&.active {
								background-color: $brandcolor1-light;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 575.9px) {
	.navbar-expand-xl {
		.navbar-collapse {
			position: relative;
			width: 100vw !important;
			left: 0;
			top: 0;
			.navbar-nav {
				.nav-link, a.dropdown-item, .link-text-nav {
					text-align: left;
				}
				.nav-item {
					width: 100%;
					.nav-link {
						padding: 1.875rem 1.875rem !important;
						font-size: clamp(1.125rem, calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
						font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
						font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					}
					.dropdown-toggle {
						&::after {
							font-size: 1.25rem;
							margin-left: 0.125rem;
						}
						&.dropdown-item {
							&::after {
								font-size: 1.25rem;
							}
						}
					}
					.dropdown-toggle-mobile {
						width: 5.0rem !important;
					}
					.dropdown-menu {
						padding: 0;
						transition-duration: 0s;
						border: none;
						&.submenu {
						}
						a.dropdown-item {
							padding: 1.875rem 1.875rem !important;
							font-size: clamp(1.5rem, calc(1.5rem + 0.25 * ((100vw - 36rem) / 39)), 1.75rem);
							font-size: clamp(1.5rem, -webkit-calc(1.5rem + 0.25 * ((100vw - 36rem) / 39)), 1.75rem);
							font-size: clamp(1.5rem, -moz-calc(1.5rem + 0.25 * ((100vw - 36rem) / 39)), 1.75rem);
							text-align: left;
						}
					}
				}
			}
		}
	}
	.navbar-toggler {
		margin-left: auto;
		margin-right: auto;
		font-size: clamp(1.125rem, calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem) !important;
		font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem) !important;
		font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem) !important;
	}
}
