/******************************************************************************************
PIXEL TO REM CONVERSION TABLE - Root Size: 16px
-------------------------------------------------------------------------------------------
PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM
-------------------------------------------------------------------------------------------
10 = 0.625   |  20 = 1.25    |  30 = 1.875   |  40 = 2.5     |  50 = 3.125   |  60 = 3.75
11 = 0.6875  |  21 = 1.3125  |  31 = 1.9375  |  41 = 2.5625  |  51 = 3.1875  |  61 = 3.8125
12 = 0.75    |  22 = 1.375   |  32 = 2       |  42 = 2.625   |  52 = 3.25    |  62 = 3.875
13 = 0.8125  |  23 = 1.4375  |  33 = 2.0625  |  43 = 2.6875  |  53 = 3.3125  |  63 = 3.9375
14 = 0.875   |  24 = 1.5     |  34 = 2.125   |  44 = 2.75    |  54 = 3.375   |  64 = 4
15 = 0.9375  |  25 = 1.5625  |  35 = 2.1875  |  45 = 2.8125  |  55 = 3.4375
16 = 1       |  26 = 1.625   |  36 = 2.25    |  46 = 2.875   |  56 = 3.5
17 = 1.0625  |  27 = 1.6875  |  37 = 2.3125  |  47 = 2.9375  |  57 = 3.5625
18 = 1.125   |  28 = 1.75    |  38 = 2.375   |  48 = 3       |  58 = 3.625
19 = 1.1875  |  29 = 1.8125  |  39 = 2.4375  |  49 = 3.0625  |  59 = 3.6875
-------------------------------------------------------------------------------------------
******************************************************************************************/

// Basic Colors
$white: #FFFFF8;
$black: #000000;

// Main Brand Color
$brandcolor1-white1: #e8f5fc;
$brandcolor1-light2: #419bcc;
$brandcolor1-light: #226f99;
$brandcolor1: #164a66;
$brandcolor1-dark: #0b2533;
$brandcolor1-dark2: #03121a;

$brandcolor2-white1: #eefce8;
$brandcolor2-light2: #c8dbc1;
$brandcolor2-light: #99a894;
$brandcolor2: #61755b;
$brandcolor2-dark: #32422d;

$brandcolor3-light: #ffefa1;
$brandcolor3: #ccbc6d;
$brandcolor3-dark: #998832;

// Grays
$gray-white1: #f4f2fa;
$gray-white2: #f1f0f7;
$gray-white3: #efedf5;

$gray-light1: #ecebf2;
$gray-light2: #ceccd9;
$gray-light3: #b3b0bf;

$gray-mid1: #9895a6;
$gray-mid2: #7f7b8c;
$gray-mid3: #666373;

$gray-dark1: #4e4b59;
$gray-dark2: #383640;
$gray-dark3: #212026;
$gray-dark4: #18171a;


//Main Brand Fonts
$font-body1: azo-sans-web, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-body2: ff-tisa-web-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
$font-heading1: ff-tisa-web-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
$font-heading2: azo-sans-web, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-nav1: azo-sans-web, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-nav2: ff-tisa-web-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
$font-button1: azo-sans-web, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-button2: ff-tisa-web-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;

//Icon Fonts
$font-icons1: 'Font Awesome 5 Free';
$font-icons2: 'BallEggleston';
