.entry-pages-home {

	#about-us {
		z-index: 0;
		overflow: visible;
		.block-background-image-overlay {
			width: auto;
			height: 150%;
		}
	}

	#practice-callouts {
	}

	#our-attorneys {
	}

	#staff-photos {
			background: -moz-linear-gradient(180deg, rgba($brandcolor1,0) 0%, rgba($brandcolor1,0.5) 100%);
			background: -webkit-linear-gradient(180deg, rgba($brandcolor1,0) 0%, rgba($brandcolor1,0.5) 100%);
			background: linear-gradient(180deg, rgba($brandcolor1,0) 0%, rgba($brandcolor1,0.5) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$brandcolor1",endColorstr="$brandcolor1",GradientType=1);
		.block-overlay-full {
			background: -moz-linear-gradient(180deg, rgba($brandcolor1-dark,0) 80%, rgba($brandcolor1-dark,0.80) 100%);
			background: -webkit-linear-gradient(180deg, rgba($brandcolor1-dark,0) 80%, rgba($brandcolor1-dark,0.80) 100%);
			background: linear-gradient(180deg, rgba($brandcolor1-dark,0) 80%, rgba($brandcolor1-dark,0.80) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$brandcolor1-dark",endColorstr="$brandcolor1-dark",GradientType=1);
			// background-blend-mode: multiply;
			// mix-blend-mode: darken;
			z-index: 0;
		}
	}

	#overview-video {
		color: $white;
		.row {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	#awards {
		h3 {
			color: $gray-mid3;
			font-weight: 400;
			font-style: normal;
		}
	}

	#latest-news {
	}

}
