.carousel-item {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.carousel-overlay {
	opacity: 0.5;
	background: linear-gradient(180deg, rgba($brandcolor1-dark,0.5) 0%, rgba($brandcolor1-dark,0.3) 33%, rgba($brandcolor1-dark,0.2) 66%, rgba($brandcolor1-dark,0.5) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.carousel-overlay-2 {
	opacity: 0.5;
	background: radial-gradient(circle, rgba($brandcolor1-dark,0.3) 0%, rgba($brandcolor1-dark,0.5) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.carousel-caption {
	position: absolute;
	left: initial;
	right: initial;
	bottom: 50%;
	transform: translateY(50%);
	width: 100%;
	display: flex;
	justify-content: space-around;
	@media (max-width: 575.9px) {
		font-size: 0.875em;
	}
	.caption-container {
		display: flex;
		justify-content: center;
		align-items: center;
		// width: auto;
		margin: 0;
		padding: 0 2.5em;
	}
	.caption-overlay-image {
		width: 9.875em;
		height: auto;
		padding: 0 0 1.0em 0;
	}
	.caption-headline-block {
		position: relative;
		padding-bottom: 0;
		width: 60%;
		@media (max-width: 991.9px) {
			width: 80%;
		}
		@media (max-width: 767.9px) {
			width: 100%;
		}
		h1 {
			position: relative;
			margin: 0 0 0.125em 0;
			padding: 0;
			font-size: 4.0em;
			font-weight: 400;
			line-height: 1;
			color: $white;
			sup {
				small {
					font-size: 0.5em;
				}
			}
			&::after {
				display: block;
				content: '';
				position: absolute;
				top: -0.9375em;
				left: 50%;
				width: .875em;
				height: .875em;
				transform: translateX(-50%);
				background-image: url('/assets/images/Branding/ball-eggleston-symbol-only.svg');
				background-size: cover;
				background-repeat: no-repeat;
				@media (max-width: 1175.9px) {
				}
			}
		}
		h3 {
			margin: 0;
			font-size: 1.75em;
			font-weight: 100;
			font-style: normal;
			color: $white;
			sup {
				small {
					font-size: 0.5em;
				}
			}
		}
		.caption-body {
			display: inline-block;
			margin: 1.25em 0 0 0;
			padding: 0.75em 0 0 0;
			border-top: 2px solid $brandcolor3;
			p {
				font-family: $font-body1;
				font-weight: 400;
				font-size: 1.0em;
				line-height: 1;
				letter-spacing: 0.75em;
				text-transform: uppercase;
				color: $white !important;
			}
		}
		.caption-buttons {
			margin: 1.25em 0 0 0;
			padding: 0;
		}
	}
}

.carousel-control-prev, .carousel-control-next {
	width: auto;
	padding: 1.25em;
	background-image: none;
  border-radius: 50%;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
}

.carousel-control-prev-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: $font-icons1; font-weight: 900; content: "\f053";
	color: $white;
	font-size: 2.625em;
}

.carousel-control-next-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: $font-icons1; font-weight: 900; content: "\f054";
	color: $white;
	font-size: 2.625em;
}

.carousel-indicators {
	left: 0;
	justify-content: center;
	margin: 0.625em 0;
	z-index: 2;
}

.carousel-indicators li {
	width: 0.625em;
	height: 0.625em;
	border-radius: 50%;
	background: none;
	border: 0.125em solid $white;
	opacity: 1.0;
	margin: 0 0.1em !important;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-webkit-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	-moz-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	-ms-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	-o-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	transition: background-color 1.0s ease-out, border 1.0s ease-out;
	&:hover {
		background-color: $white;
		border: 0.3125em solid $white;
	}
	&.active {
		background-color: $brandcolor2;
		border: 0.125em solid $brandcolor2;
	}
}

#banner-down-arrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 94vh;
	@supports (height:100dvh) {
		height: 94dvh;
	}
	z-index: 1;
	text-align: center;
	pointer-events: none;
	.banner-down-arrow-text {
		position: absolute;
		bottom: 11.0em;
		left: 0;
		width: 100vw;
		opacity: 0.4;
		color: $white;
		font-size: 0.625em;
		font-weight: 700;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		-webkit-transition: opacity 1.0s ease-out;
		-moz-transition: opacity 1.0s ease-out;
		-ms-transition: opacity 1.0s ease-out;
		-o-transition: opacity 1.0s ease-out;
		transition: opacity 1.0s ease-out;
	}
	.banner-down-arrow-icon {
		a {
			position: absolute;
			bottom: 4.0em;
			left: 0;
			width: 100vw;
			-webkit-animation: anim-banner-down-arrow 2s infinite;
			animation: anim-banner-down-arrow 2s infinite;
			-webkit-transition: opacity 1.0s ease-out, translate 1.0s ease-out;
			-moz-transition: opacity 1.0s ease-out, translate 1.0s ease-out;
			-ms-transition: opacity 1.0s ease-out, translate 1.0s ease-out;
			-o-transition: opacity 1.0s ease-out, translate 1.0s ease-out;
			transition: opacity 1.0s ease-out, translate 1.0s ease-out;
			.fa, .fac {
				font-size: 3.125em;
				color: $white;
				&:hover {
					color: $brandcolor2;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor2-dark;
				}
			}
		}
	}
}

@-webkit-keyframes anim-banner-down-arrow {
	0% {
		-webkit-transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(2.0em);
		opacity: 0;
	}
}

@keyframes anim-banner-down-arrow {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(2.0em);
		opacity: 0;
	}
}