/* News Article Teaser Cards */
.card-news-container-outer {
	margin: 0;
	padding: 0 2.5em 5.0em 2.5em;
	@media (max-width: 767.9px) {
		padding: 0 2.0em 5.0em 2.0em;
	}
	.card-news-container-inner {

	}
}

.card-news-row {
	.col-12:not(:last-child) {
		@media (min-width: 992px) {
			border-right: 1px solid $gray-white2;
		}
		@media (max-width: 991.9px) {
			border-bottom: 1px solid $gray-white2;
		}
	}

	.col-12 + .col-12 {
		@media (min-width: 992px) {
			margin-left: -1px;
		}
		@media (max-width: 991.9px) {
			margin-top: -1px;
		}
	}
}

.card-news-row-lg {
	margin: 0;
	.col-12:first-child {
		padding: 0.9375em 0 1.125em 0;
	}
	.col-12 {
		padding: 1.125em 0;
	}
	.col-12:not(:last-child) {
		border-bottom: 1px solid $gray-white2;
	}
	.col-12 + .col-12 {
		margin-top: -1px;
	}
}

.card-news {
	margin: 1.25em 0;
	@media (max-width: 991.9px) {
		margin: 2.5em 0;
	}
	.card-img-top {
		margin-bottom: 1.25rem;
	}
	.card-news-header {
		.card-title {
			font-size: 1.5rem;
		}
	}
	.card-news-body {
		font-size: 1.0em;
		line-height: 1.25;
	}
}

/* Main Articles Page */
.articles-categories-container {
	padding: 0 2.5em 0 2.5em !important;
	.category-list-title {
		padding-bottom: 0.25em;
		border-bottom: 1px solid $brandcolor3;
	}
	ul {
		margin: 0;
		padding: 0;
		li {
			font-family: $font-nav1;
			font-size: 0.875rem;
			@media (max-width: 575.9px) {
				font-size: 1.0rem;
			}
			a {
				font-weight: 700;
				&:hover {
					text-decoration: none;
				}
				@media (max-width: 991.9px) {
					display: block;
					padding: 0.3125rem 0;
				}
				@media (max-width: 575.9px) {
					padding: 0.625rem 0;
				}
			}
		}
	}
}

/* News Detail Pages */
.news-body-container {
	padding: 0 2.5em 2.5em 2.5em;
	.news-container {
		padding: 1.875em 2.5em 0 2.5em;
		.news-article-featured-image {
			margin-bottom: 5.0rem;
		}
	}
}

.news-subheadline-text {
	font-size: 1.5em;
	b {
		font-weight: 400;
		color: $gray-dark1;
	}
}

