header {
	.header-top-container {
		background-color: $white;
		padding: 0.9375em 0;
		.row {
			display: flex;
			justify-content: center;
			align-items: center;
			@media (max-width: 575.9px) {
				justify-content: center;
			}
		}
		.header-logo {
			display: inline-block;
			width: 520px;
			height: 80px;
			background: url("/assets/images/Branding/ball-eggleston-logo-onwhite.svg") center center/contain no-repeat;
			text-indent: -9999px;
			margin: 0.625em 0.625em;
			@media (max-width: 991.9px) {
				width: 390px;
				height: 60px;
			}
			@media (max-width: 767.9px) {
				width: 260px;
				height: 40px;
			}
			@media (max-width: 575.9px) {
				width: 75vw;
				height: 80px;
			}
		}
		.header-info-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			// gap: 1.25rem;
			width: auto;
			.header-phone-container {
				padding: 0.625em;
				font-family: $font-body2;
				font-size: 1.125rem;
				line-height: 1.125;
				white-space: nowrap;
				text-align: right;
				border-right: 1px solid $gray-light2;
				.header-phone-link {
					font-family: $font-body1;
					font-size: 1.0rem;
					font-weight: 400;
					&:hover {
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						text-decoration: none;
					}
					.fa, .fac {
						font-size: 0.875rem;
					}
				}
			}
			.header-info-buttons {
				padding: 0.625em;
				.btn-sm {
					padding: 0.75em 2.0em;
				}
			}
		}
	}
	.header-nav-container {
		background-color: $brandcolor1-dark;
		padding: 0;
		.navbar {
			padding: 0;
		}
	}
}
