// Blocks General
.content-blocks-container {
	// position: relative;
	// display: flex;
	// flex-direction: column;
	// justify-content: flex-start;
	// align-items: center;
	// height: 100%;
	// overflow-x: hidden;
	// overflow-y: auto;
	// scroll-snap-type: y proximity;
	// -webkit-overflow-scrolling: touch;
}

.block-outer-container {
	position: relative;
	margin: 0 !important;
	padding: 0 !important;
}

.block-inner-container {
	position: relative;
	padding: 0 !important;
}

.block-scroll-snap-center {
	scroll-snap-align: center;
	scroll-snap-stop: always;
}

.block-outer-container-center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.block-inner-container-center-content {
	display: flex;
	justify-content: center;
	align-items: center;
	.row {
		width: 100%;
	}
}

.block-headline-text {
	margin: 0;
	line-height: 1;
}

.block-subheadline-text, .block-subheadline-text p {
	margin: 0.3125em 0 0 0;
	text-transform: none;
	line-height: 1;
}

.block-button-group-container {
	padding: 1.25rem 0;
}

.block-background-image-overlay {
	position: absolute;
	z-index: -10;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.block-background-image-fixed {
	background-attachment: fixed !important;
	@supports (-webkit-touch-callout: none) {
		background-attachment: scroll !important;
	}
}

.block-background-video-fixed {
	position: fixed !important;
	left: 0;
	top: 0;
}

.block-background-image-cover {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.block-background-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
	object-fit: cover;
	object-position: 50% 50%;
	overflow: hidden;
}

.block-container-full, .block-container-content {
	position: relative;
}

.block-overlay-full, .block-overlay-content {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.divider-block-intro {
	visibility: hidden;
	margin: 0.3125rem 0;
}



// Images
.img-regular-padding {
	padding: 0 1.25em;
	@media (max-width: 991.9px) {
		padding: 2.5em 1.25em;
	}
}

.img-no-padding {
	padding: 0;
	@media (max-width: 991.9px) {
		padding: 2.5em 0;
	}
}

.img-square-crop, .img-circle-crop {
	position: relative;
}
.img-square-crop::after, .img-circle-crop::after {
	content: "";
	display: block;
	padding-bottom: 100%;
}
.img-square-crop img, .img-circle-crop img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-repeat: no-repeat;
	@media (max-width: 991.9px) {
		padding: 2.5em;
	}
}

.img-circle-crop img {
	border-radius: 50%;
}

.img-overlay-sections {
	position: absolute;
	right: 2.5em;
	top: 0;
	@media (max-width: 991.9px) {
		position: static;
		padding: 2.5em 1.25em;
	}
}

.img-background-fill {
	display: flex;
	justify-content: stretch;
	align-items: flex-end;
	align-self: stretch;
	height: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	figure {
		display: flex;
		justify-content: stretch;
		align-items: flex-end;
		visibility: visible;
		width: 100%;
		height: 100%;
		img, a img {
			visibility: hidden;
			width: 100%;
		}
		figcaption {
			visibility: visible;
			width: 100%;
		}
	}
}
.block-single-image-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}




// Large Text Callout
.large-text-callout-container {
	h2.large-text-callout-headline-text {
		font-size: 2em;
	}
	h3.large-text-callout-headline-text {
		font-size: 1.75em;
	}
	h4.large-text-callout-headline-text {
		font-size: 1.5em;
	}

	h4.large-text-callout-subheadline-text {
		font-size: 1.5em;
	}
	h5.large-text-callout-subheadline-text {
		font-size: 1.25em;
	}
	h6.large-text-callout-subheadline-text {
		font-size: 1.0em;
	}
	.large-text-callout-body-copy {
		p {
			font-family: $font-body2;
			font-weight: 400;
			font-size: 2.5em;
			line-height: 1.2em;
		}
	}
	cite.large-text-callout-citation {
		display: block;
		font-family: $font-body2;
		font-weight: 400;
		margin: 0.625em 0 1.25em 0;
		padding: 0;
	}
}



//Pull Quote
.pull-quote-container { 
	h2.pull-quote-headline-text {
		font-size: 2em;
	}
	h3.pull-quote-headline-text {
		font-size: 1.75em;
	}
	h4.pull-quote-headline-text {
		font-size: 1.5em;
	}
	h5.pull-quote-headline-text {
		font-size: 1.25em;
	}
	h4.pull-quote-subheadline-text {
		font-size: 1.5em;
	}
	h5.pull-quote-subheadline-text {
		font-size: 1.25em;
	}
	h6.pull-quote-subheadline-text {
		font-size: 1.0em;
	}
	.pull-quote-body-copy {
		p {
			font-family: $font-body2;
			font-weight: 100;
			font-style: italic;
			font-size: 2.0em;
			line-height: 1.25em;
		}
	}
	cite.pull-quote-citation {
		display: block;
		color: $gray-dark3;
		font-family: $font-body2;
		font-size: 1.5em;
		font-weight: 400;
		line-height: 1em;
		margin: 0.625em 0 1.25em 0;
		padding: 0;
	}
}



// Text-With-Image Block
.block-text-image-body-column {
	padding: 0 0.9375em !important;
}



// Accordion List Block
.accordion-block {
	margin: 0;
	padding: 0;
	.card {
		margin: 0;
		padding: 0;
		.card-header {
			margin: 0;
			padding: 0.9375em 0;
			border-bottom: 1px solid $gray-light2;
			.btn {
				margin: 0;
				padding: 0;
				padding-right: 5.0rem;
				font-family: $font-heading1;
				font-weight: 400;
				font-style: italic;
				// font-size: 2em;
				font-size: clamp(1.25rem, -webkit-calc(1.25rem + 0.5 * ((100vw - 36em) / 39)), 1.75em);
				font-size: clamp(1.25rem, -moz-calc(1.25rem + 0.5 * ((100vw - 36em) / 39)), 1.75em);
				font-size: clamp(1.25rem, calc(1.25rem + 0.5 * ((100vw - 36em) / 39)), 1.75em);
				line-height: 1;
				color: $brandcolor1-light;
				text-transform: none;
				letter-spacing: 0;
				.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
					outline: 0 !important;
					outline-offset: 0 !important;
					background-image: none !important;
					-webkit-box-shadow: none !important;
					-moz-box-shadow: none !important;
					box-shadow: none !important;
				}
				&[aria-expanded="true"] {
					.fac { transform: rotate(180deg); }
				}
				.fac {
					display: inline-block;
					position: absolute;
					top: 0.5625em;
					right: 0.5625em;
					transition: transform 300ms ease-in-out;
					transform: rotate(0deg);
					color: $brandcolor2;
				}
			}
		}
		.card-body {
			font-size: 1.0rem;
			padding: 1.25em 0.9375em 1.25em 0.9375em;
			p { font-size: 1.0rem; }
			h3 { 
				font-size: 1.875rem;
				color: $gray-dark1;
			}
		}
	}
}



//Stat Facts Row
.block-container-stats-facts-row {
	.stat-fact-outer-container {
		position: relative;
		// &::before {
		// 	content: "";
		// 	display: block;
		// 	padding-bottom: 100%;
		// 	position: absolute;
		// 	right: 0;
		// 	top: -55%;
		// 	width: 100%;
		// 	border-radius: 50%;
		// 	background-color: rgba($brandcolor1-light, 0.15);
		// }
		.stat-fact-inner-container {
			position: relative;
			z-index: 1;
			.stat-fact-number {
				display: inline;
				margin: 0;
				padding: 0;
				font-family: $font-body1;
				font-size: 5em;
				font-weight: 100;
				line-height: 1;
				letter-spacing: -0.4rem;
			}
			.stat-fact-prefix {
				display: inline;
				margin: 0;
				padding: 0;
				font-family: $font-body1;
				font-size: 3em;
				font-weight: 100;
				line-height: 1;
				letter-spacing: -0.2rem;
			}
			.stat-fact-suffix {
				display: inline;
				margin: 0;
				padding: 0;
				font-family: $font-body1;
				font-size: 3em;
				font-weight: 100;
				line-height: 1;
				letter-spacing: -0.2rem;
			}
		}
	}
	.stat-fact-desc {
		margin: 0;
		padding: 0 1.25rem;
		font-size: 1.2em;
		line-height: 1;
	}
	.stat-fact-citation {
		margin: 0;
		padding-top: 0.625em;
		font-size: 0.9em;
		font-weight: 100;
		line-height: 1;
		// color: $gray-mid3;
	}
}




//Image Callout Cards
.block-container-image-callout-cards {
	.card {
		border: none;
		border-radius: 0;
		.card-body {
			a {
				// color: $brandcolor1-light;
				text-decoration: none;
				&:hover {
					color: $brandcolor1-light2;
					text-decoration: none;
					.image-callout-headline {
						color: $brandcolor1-light2;
						text-decoration: none;
					}
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
					text-decoration: none;
					.image-callout-headline {
						color: $brandcolor1;
						text-decoration: none;
					}
				}
			}
			p {
				font-size: 1.125em;
				line-height: 1.4em;
			}
			.image-callout-headline {
				margin: 0 !important;
				line-height: 1;
			}
			.image-callout-subheadline {
				margin: 0 !important;
				line-height: 1;
			}
		}
	}
}



//Background Image Callout Cards
.background-callout-card {
	width: 100%;
	border: none;
	border-radius: 0;
	.card-img {
		border-radius: 0;
	}
	.card-img-overlay {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding-bottom: 5vw;
		.background-callout-headline {
			color: $white;
			font-size: 2.25em;
			line-height: 0.875;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}
		.background-callout-body p {
			font-size: 1.0em;
			line-height: 1.2em;
		}
	}
}



//Image Icon Row
.block-container-image-icon-row {
	.image-icon-container {
		padding: 1.25em;
	}
	.image-icon-headline {
		margin: 0.3125em 0 0 0;
		color: $gray-mid3;
	}
	.image-icon-link {
		&:hover {
			text-decoration: none;
		}
	}
	.image-icon-text p {
		color: $gray-dark2;
		line-height: 1.2em;
		margin-top: 1.25em;
	}
	.image-icon-card {
		height: 100%;
		margin: 0;
		padding: 0 0 2.5em 0;
		display: flex;
		justify-content: center;
		align-items: flex-start; 
		.image-icon-card-img-top {
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-grow: 1;
			.image-icon-image {
				margin: 0;
				padding: 0;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.image-icon-card-body {
			width: 100%;
			margin: 0;
			padding: 0;
			align-self: flex-end;
			flex-grow: 0;
		}
		.image-icon-card-footer {
			margin: 0;
			padding: 0;
			align-self: flex-end;
		}
	}
}



//Image Gallery Block
.block-container-image-gallery {
	.block-image-gallery-thumb-container {
		padding: 0.3125em;
		.block-image-gallery-thumb {
		}
	}
	.block-image-gallery-masonry-grid-sizer,
	.block-image-gallery-masonry-grid-item { 
		padding: 0.3125em;
	}
}



//Video Block
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0; overflow:
	hidden;
	max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}



//People Slider Block
.people-slider {
	width: 100vw;
	z-index: 0;
	.people-slider-wrapper {
		// -webkit-transition-timing-function: linear !important;
		// -moz-transition-timing-function: linear !important;
		// -ms-transition-timing-function: linear !important;
		// -o-transition-timing-function: linear !important;
		// transition-timing-function: linear !important;
		// -webkit-transition-duration: 3s !important;
		// -moz-transition-duration: 3s !important;
		// -ms-transition-duration: 3s !important;
		// -o-transition-duration: 3s !important;
		// transition-duration: 3s !important;
	}
	.people-slide {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		overflow: visible;
		&:hover {
			.people-info {
				opacity: 1;
			}
		}
		.people-image {
			img {
				max-width: initial !important;
				display: block;
				// width: auto;
				// height: 100%;
				object-fit: cover;
				overflow: visible;
				filter: drop-shadow(0 10px 10px rgba($brandcolor1-dark, 0.4));
			}
		}
		// .people-image-overlay {
		// 	display: block;
		// 	position: absolute;
		// 	left: -25%;
		// 	right: 25%;
		// 	top: 0;
		// 	bottom: 0;
		// 	width: 150%;
		// 	height: 100%;
		// 	background: -moz-linear-gradient(180deg, rgba($brandcolor1-dark,0) 70%, rgba($brandcolor1-dark,0.80) 100%);
		// 	background: -webkit-linear-gradient(180deg, rgba($brandcolor1-dark,0) 70%, rgba($brandcolor1-dark,0.80) 100%);
		// 	background: linear-gradient(180deg, rgba($brandcolor1-dark,0) 70%, rgba($brandcolor1-dark,0.80) 100%);
		// 	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$brandcolor1-dark",endColorstr="$brandcolor1-dark",GradientType=1);
		// 	// background-blend-mode: multiply;
		// 	// mix-blend-mode: darken;
		// }
		.people-info {
			display: block;
			position: absolute;
			left: -33%;
			bottom: 0;
			width: 150%;
			margin: 0;
			padding: 1.25rem 0.625rem 2.5rem 0.625rem;
			text-align: center;
			color: $white;
			background: -moz-linear-gradient(90deg, rgba($brandcolor1-dark,0) 0%, rgba($brandcolor1-dark,1.0) 25%, rgba($brandcolor1-dark,1.0) 75%, rgba($brandcolor1-dark,0) 100%);
			background: -webkit-linear-gradient(90deg, rgba($brandcolor1-dark,0) 0%, rgba($brandcolor1-dark,1.0) 25%, rgba($brandcolor1-dark,1.0) 75%, rgba($brandcolor1-dark,0) 100%);
			background: linear-gradient(90deg, rgba($brandcolor1-dark,0) 0%, rgba($brandcolor1-dark,1.0) 25%, rgba($brandcolor1-dark,1.0) 75%, rgba($brandcolor1-dark,0) 100%);
			opacity: 0;
			-webkit-transition: opacity 1.0s ease-out;
			-moz-transition: opacity 1.0s ease-out;
			-ms-transition: opacity 1.0s ease-out;
			-o-transition: opacity 1.0s ease-out;
			transition: opacity 1.0s ease-out;
			.people-info-name {
				font-weight: 700;
				line-height: 1;
			}
			.people-info-type {
				font-family: $font-body2;
				font-weight: 100;
				font-style: italic;
				line-height: 1;
			}
		}
	}
}
