.page-title-outer-container {
	overflow: hidden;
	.page-title-full-width {
		position: absolute;
		background-color: $gray-light1;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		-webkit-background-size: cover !important;
		-moz-background-size: cover !important;
		-o-background-size: cover !important;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: center top;
		background-attachment: fixed;
		-webkit-filter: blur(20px) grayscale(25%);
		-moz-filter: blur(20px) grayscale(25%);
		-o-filter: blur(20px) grayscale(25%);
		filter: blur(20px) grayscale(25%);
		opacity: 0.75;
		overflow: hidden;
	}
	.page-title-back {
		background-color: $gray-dark2;
		position: relative;
		-webkit-background-size: cover !important;
		-moz-background-size: cover !important;
		-o-background-size: cover !important;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		background-position: 50% 50%;
		background-attachment: fixed;
		max-width: 2200px;
		z-index: 2;
		.page-title-overlay {
			// opacity: 1.0;
			// background: linear-gradient(180deg, rgba($brandcolor1-dark,0.8) 0%, rgba($brandcolor1-dark,0.33) 33%, rgba($brandcolor1-dark,0.33) 66%, rgba($brandcolor1-dark,0.8) 100%);
			// position: absolute;
			// left: 0;
			// top: 0;
			// width: 100%;
			// height: 100%;
		}
	}
}

.page-title-container {
	padding: 4.6875em 2.5em 3.75em 2.5em;
	.page-title-backlink {
		display: inline-block;
		font-weight: 400;
		margin: 0 0 0.875rem 0;
		.fa {
			color: $brandcolor3;
		}
		&:hover {
			text-decoration: none;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			text-decoration: none;
		}
		@media (max-width: 575.9px) {
			display: block;
		}
	}
	.page-title {
		// display: block;
		margin: 0;
		padding: 0;
		width: 100%;
		border-bottom: 1px solid $brandcolor3;
	}
	.page-subtitle-container {
		padding: 0 1.5em 1.875em 1.5em;
	}
}

.entry-pages-attorneys {
	.page-title-container {
		padding-bottom: 0;
		display: block;
		position: relative;
		z-index: 2;
	}
}
