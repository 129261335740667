footer {
	@media (max-width: 575.9px) {
		font-size: 1.0625em;
	}
}

//Footer Top Rows
.footer-top {
	background-color: $brandcolor1;
	.footer-top-inner {
		padding: 5.0em 1.25em;
		.footer-column {
			padding: 0 0.625em;
			text-align: left;
			color: $white;
			@media (max-width: 991.9px) {
				padding: 2.5em 0.625em;
			}
			@media (max-width: 767.9px) {
				text-align: center;
				padding: 2.5em 0;
			}
		}
		.footer-about-container {
			p {
				font-family: $font-body2;
				font-weight: 100;
				font-size: 0.8125em;
				line-spacing: 1.75;
				color: $white;
				@media (max-width: 575.9px) {
					font-size: 1.125rem;
					padding-bottom: 0.625rem;
				}
			}
			a {
				font-family: $font-nav1;
				color: $white;
				text-decoration: underline;
				&:hover {
					color: $brandcolor1-light2;
					text-decoration: underline;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-white1;
					text-decoration: underline;
				}
			}			
		}
		.footer-contact-container {
			.contact-phone {
				font-family: $font-nav1;
				font-size: 1.25em;
				color: $brandcolor2-light;
				@media (max-width: 575.9px) {
					display: block;
					padding: 1.125rem 0;
				}
			}
			.footer-contact-link {
				font-family: $font-nav1;
				font-size: 0.875em;
				text-decoration: underline;
			}
			p {
				font-family: $font-body2;
				font-weight: 100;
				font-size: 1em;
				line-height: 1;
				color: $white;
				@media (max-width: 575.9px) {
					font-size: 1.25rem;
					line-height: 1.2;
				}
			}
			a {
				color: $white;
				&:hover {
					color: $brandcolor1-light2;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-white1;
					text-decoration: none;
				}
			}
		}
		h5 {
			margin-bottom: 1.25em;
		}
		.footer-links {
			ul.unstyled {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					font-family: $font-nav1;
					line-height: 1;
					a {
						padding-left: 0;
						color: $white;
						@media (max-width: 575.9px) {
							padding: 1.25rem 0 !important;
						}
						&:hover {
							color: $brandcolor1-light2;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $brandcolor1-white1;
							text-decoration: none;
						}
					}
				}
			}
		}
		.footer-social-media {
			padding-top: 2.5em;
		}
		.footer-social-icon {
			color: $white;
			line-height: 1;
			&:hover {
				color: $brandcolor1-light2;
				text-decoration: none;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1-white1;
				text-decoration: none;
			}
		}
		.footer-social-desc {
			display: none;
		}
	}
}

//Footer Middle Rows
.footer-middle {
	background-color: $brandcolor1-dark;
	.footer-middle-inner {
		padding: 3.75em 2.5em;
		.footer-buttons-title {
			display: flex;
			justify-content: center;
			align-items: center;
			h5 {
				display: inline;
				margin: 0;
				padding: 0 0.625em;
				line-height: 1;
				text-align: right;
				border-right: 2px solid $brandcolor1-light;
			}
			p {
				display: inline;
				margin: 0;
				padding: 0 0.625em;
				font-family: $font-body2;
				font-size: 1.0em;
				font-weight: 100;
				line-height: 1;
				color: $white;
				text-align: left;
			}
		}
		.footer-buttons {
			padding: 0.625em 0;
			display: flex;
			justify-content: space-around;
			align-items: center;
			@media (max-width: 767.9px) {
				flex-direction: column;
			}
			.image-button {
				padding: 1.25em;
			}
		}
	}
}

// Footer Bottom Rows
.footer-bottom {
	background-color: $gray-dark4;
	.footer-bottom-inner {
		padding: 2.1875em 1.25em;
		text-align: center;
		.row-top {
			padding: 0em;
		}
		.row-bottom {
			padding: 1.25em 0;
		}
		.footer-disclaimer {
			color: $brandcolor2-light;
			font-family: $font-body2;
			font-size: 1.0em;
		}
		.footer-copyright {
			color: $white;
			font-size: 0.75em;
			line-height: 1;
			@media (max-width: 575.9px) {
				margin-top: 1.25rem;
			}
		}
		.footer-links {
			ul {
				li.list-inline-item {
					margin: 0;
					padding: 0 0.625em;
					@media (max-width: 575.9px) {
						padding: 0.625rem 1.25em;
					}
					&:not(:last-child) {
						border-right: 1px solid $gray-mid2;
					}
					a {
						font-family: $font-nav1;
						font-size: 1em;
						line-height: 1;
						color: $white;
						&:hover {
							color: $brandcolor1-light2;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $brandcolor1-white1;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}
