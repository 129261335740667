.body, body { 
	font-family: $font-body1;
	font-weight: 100;
	font-style: normal;
	line-height: 1.6;
	color: $gray-mid3;
}

.body {
	p {
		font-size: 1.25rem;
		font-weight: 100;
		line-height: 1.6;
		a {
			color: $brandcolor1-light;
			text-decoration: underline;
			&:hover {
				color: $brandcolor1;
				text-decoration: underline;
			}
		}
	}
	ul {
		//list-style: none; /* Remove default bullets */
		margin: 1.0em 0 1.0em 0.3125em;
	}
	ul.sitemap-links {
		margin: 0 0 0 0.3125em;
		li:first-child {
			margin-top: 0;
		}
	}

	ul li::marker {
		content: "\2580";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: $brandcolor3; /* Change the color */
		font-weight: bold; /* If you want it to be bold */
		// display: inline-block; /* Needed to add space between the bullet and the text */
		// width: 2.5rem;  /* Also needed for space (tweak if needed) */
		// margin-left: -2.5rem; /* Also needed for space (tweak if needed) */
		// transform: translateY(0.25em);
		font-size: 0.75rem;
	}

	ul li {
		font-size: 1rem;
		line-height: 1.25;
		margin: 1.0em 0 1.0em -1.875rem;
		padding-left: 0.625rem;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	ul li a {
		// font-weight: 400;
		// text-decoration: underline;
		// color: $black !important;
	}

	ul li a:hover {
		// color: $brandcolor1 !important;
	}

	ol li {
		line-height: 1.25;
		margin: 0.66em 0;
	}

	ul.unstyled, ul.list-unstyled {
		list-style-type: none;
		margin: 0 0 2.5em 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0.625em 0;
			line-height: 1;
			border-bottom: 1px solid $gray-light1;
			&:first-child { 
				// padding-top: 0; 
			}
			&:last-child { 
				border-bottom: none; 
			}
			&::marker {
				content: "";
				display: none;
			}
			b {
				color: $black;
				font-weight: 700;
			}
			&.active {
				a {
					color: $gray-mid3;
				}
			}
		}
	}

	ul.list-2column {
		// margin-top: 0;
		// padding-top: 0.9375rem;
		// border-top: 1px solid $brandcolor3;
		column-count: 2;
		column-gap: 2.5rem;
		column-rule: 1px solid $gray-light1;
		@include media-breakpoint-only(lg) {
			column-count: 1;
		}
		@include media-breakpoint-only(sm) {
			column-count: 1;
		}
	}

	ul.list-3column {
		// margin-top: 0;
		// padding-top: 0.9375rem;
		// border-top: 1px solid $brandcolor3;
		// display: block;
		// width: 100%;
		column-count: 3;
		column-gap: 7.5rem;
		column-rule: 1px solid $gray-light1;
		@include media-breakpoint-down(lg) {
			column-count: 2;
		}
		@include media-breakpoint-down(sm) {
			column-count: 1;
		}
	}
}

p {
	font-size: 1.125rem;
	line-height: 1.5;
}

p.lead, .lead p {
	font-family: $font-body2;
	// font-size: 1.75rem;
	font-size: clamp(1.5em, -webkit-calc(1.25em + 0.25 * ((100vw - 36em) / 39)), 1.75em);
	font-size: clamp(1.5em, -moz-calc(1.25em + 0.25 * ((100vw - 36em) / 39)), 1.75em);
	font-size: clamp(1.5em, calc(1.25em + 0.25 * ((100vw - 36em) / 39)), 1.75em);
	line-height: 1.5;
	font-style: italic;
}

p.text-small, .text-small p, .text-small {
	font-size: 0.8rem;
	line-height: 1.25;
}

b {
	font-weight: 700;
}

a {
	color: $brandcolor1-light;
	&:hover {
		color: $brandcolor1-light2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1;
	}
}

h1, h2, h3, h4, h5, h6 {
	overflow-wrap: break-word;
	// hyphens: auto;
	// -ms-hyphens: auto;
	// -moz-hyphens: auto;
}

h1.display-1 {
	font-family: $font-heading1;
	font-weight: 400;
	font-size: clamp(2.25em, -webkit-calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	font-size: clamp(2.25em, -moz-calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	font-size: clamp(2.25em, calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	line-height: 0.875;
	color: $brandcolor2-light;
	b, strong {
		font-weight: 700;
	}
}

h1.display-2 {
	font-family: $font-heading1;
	font-weight: 400;
	font-size: clamp(1.75em, -webkit-calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-size: clamp(1.75em, -moz-calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-size: clamp(1.75em, calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-style: italic;
	line-height: 0.875;
	color: $gray-mid3;
	b, strong {
		font-weight: 700;
	}
}


h1.display-3 {
	font-family: $font-heading1;
	font-weight: 100;
	font-size: clamp(2.25em, -webkit-calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	font-size: clamp(2.25em, -moz-calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	font-size: clamp(2.25em, calc(2.0em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	line-height: 0.875;
	color: $brandcolor2-light;
	b, strong {
		font-weight: 100;
	}
}

h1.display-4 {
	font-family: $font-heading1;
	font-weight: 100;
	font-size: clamp(1.75em, -webkit-calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-size: clamp(1.75em, -moz-calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-size: clamp(1.75em, calc(1.75em + 1.75 * ((100vw - 36em) / 39)), 3.5em);
	font-style: italic;
	line-height: 0.875;
	color: $gray-mid3;
	b, strong {
		font-weight: 100;
	}
}

h1 {
	font-family: $font-heading1;
	font-weight: 400;
	//font-size: 4rem;
	font-size: clamp(2.5em, -webkit-calc(2.5em + 1.5 * ((100vw - 62em) / 39)), 4.0em);
	font-size: clamp(2.5em, -moz-calc(2.5em + 1.5 * ((100vw - 62em) / 39)), 4.0em);
	font-size: clamp(2.5em, calc(2.5em + 1.5 * ((100vw - 62em) / 39)), 4.0em);
	line-height: 1;
	color: $brandcolor2-light;
	@media (min-width: 991.9px) {
		// font-size: 2.5em;
	}
	@media (max-width: 991.9px) {
		font-size: clamp(2.25em, -webkit-calc(2.25em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
		font-size: clamp(2.25em, -moz-calc(2.25em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
		font-size: clamp(2.25em, calc(2.25em + 1.75 * ((100vw - 36em) / 39)), 4.0em);
	}
}

h2 {
	font-family: $font-heading1;
	font-weight: 400 !important;
	font-style: normal;
	//font-size: 3.0rem;
	font-size: clamp(2.0em, -webkit-calc(2.0em + 1 * ((100vw - 36em) / 39)), 3.0em);
	font-size: clamp(2.0em, -moz-calc(2.0em + 1 * ((100vw - 36em) / 39)), 3.0em);
	font-size: clamp(2.0em, calc(2.0em + 1 * ((100vw - 36em) / 39)), 3.0em);
	line-height: 1;
	color: $brandcolor2-light;
}

h3 {
	font-family: $font-heading1;
	font-weight: 400;
	font-style: italic;
	//font-size: 2.25rem;
	font-size: clamp(1.5625rem, -webkit-calc(1.5625rem + 0.6875 * ((100vw - 36em) / 39)), 2.25em);
	font-size: clamp(1.5625rem, -moz-calc(1.5625rem + 0.6875 * ((100vw - 36em) / 39)), 2.25em);
	font-size: clamp(1.5625rem, calc(1.5625rem + 0.6875 * ((100vw - 36em) / 39)), 2.25em);
	line-height: 1;
	margin-bottom: 0.625rem;
	color: $brandcolor2-light;

}

h4 {
	font-family: $font-heading1;
	font-weight: 100;
	//font-size: 1.75rem;
	font-size: clamp(1.25rem, -webkit-calc(1.25rem + 0.5 * ((100vw - 36em) / 39)), 1.75rem);
	font-size: clamp(1.25rem, -moz-calc(1.25rem + 0.5 * ((100vw - 36em) / 39)), 1.75rem);
	font-size: clamp(1.25rem, calc(1.25rem + 0.5 * ((100vw - 36em) / 39)), 1.75rem);
	line-height: 1;
	color: $brandcolor1-dark;
}

h5 {
	font-family: $font-heading2;
	font-weight: 700;
	//font-size: 1.25rem;
	font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
	font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
	font-size: clamp(1.125rem, calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	color: $brandcolor2-light;
}

h6 {
	font-family: $font-heading2;
	font-weight: 700;
	//font-size: 1.125rem;
	font-size: clamp(1.0rem, -webkit-calc(1.0rem + 0.125 * ((100vw - 36em) / 39)), 1.125rem);
	font-size: clamp(1.0rem, -moz-calc(1.0rem + 0.125 * ((100vw - 36em) / 39)), 1.125rem);
	font-size: clamp(1.0rem, calc(1.0rem + 0.125 * ((100vw - 36em) / 39)), 1.125rem);
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	color: $gray-mid3;
}

.text-light {
	p, h1, h2, h3, h4, h5, h6, ul li::marker, ul li::before, a {
		color: $white !important;
	}
	p a {
		text-decoration: underline;
		&:hover {
			color: $white;
			text-decoration: underline;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $white;
			text-decoration: underline;
		}
	}
}

h1.large-quote {
	vertical-align:bottom;
	font-size: 5.5rem;
	line-height: 1;
}

h2.large-quote {
	vertical-align:bottom;
	font-size: 4.5rem;
	line-height: 1;
}

h2.page-headline-text {
	margin-bottom: 0.625rem;
}

h4.page-subheadline-text {
	margin-top: 0.625rem;
}

h6.header-xs {
	font-size: 0.875rem;
	letter-spacing: 0.05rem;
}

.footnote {
	color: $brandcolor2;
	font-weight: 700;
}
a.footnote {
	color: $brandcolor2;
	&:hover {
		color: $brandcolor1;
		text-decoration: none;
	}
}
