/* People Main Page */
.people-practice-filter-container {
	padding: 5.0rem 2.5rem 0 2.5rem;
	position: relative;
	.navbar {
		margin: 0;
		padding: 0;
		border-top: 1px solid $brandcolor3;
		.people-practice-filter-label {
			margin: 0 !important;
			padding: 0 0.5625em !important;
			white-space: nowrap;
			font-family: $font-nav1;
			font-size: 1.25em;
			line-height: 0.8;
			color: $gray-mid2;
			border-top: 5px solid rgba($brandcolor3, 0);
			@media (min-width: 991.9px) {
				display: none;
			}
			@media (max-width: 991.9px) {
				flex-grow: 1;
				padding: 0.875em 0 !important;
				cursor: pointer;
			}
		}
		.navbar-collapse {
			position: relative;
			right: 0;
			background-color: rgba($white, 0.8) !important;
			-webkit-backdrop-filter: saturate(180%) blur(8px);
			-moz-backdrop-filter: saturate(180%) blur(8px);
			-ms-backdrop-filter: saturate(180%) blur(8px);
			-o-backdrop-filter: saturate(180%) blur(8px);
			backdrop-filter: saturate(180%) blur(8px);
			/* Fallback when backdrop-filter not supported */
			@supports (not (backdrop-filter: blur(8px))) and (not (-webkit-backdrop-filter: blur(8px))) and (not (-moz-backdrop-filter: blur(8px))) and (not (-ms-backdrop-filter: blur(8px))) and (not (-o-backdrop-filter: blur(8px))) {
				background-color: rgba($white, 0.95);
			}
			.navbar-nav {
				margin: 0 0 2.5rem 0 !important;
				padding: 0 !important;
				background: none;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.nav-item {
					margin: 0 !important;
					padding: 0 !important;
					&::marker {
						content: "" !important;
						display: none !important;
					}
					@media (max-width: 991.9px) {
						width: 100%;
						border-left: 5px solid rgba($brandcolor3,0);
						border-top: 1px solid $gray-light2;
						&:last-child {
							border-bottom: 1px solid $gray-light2;
						}
					}
					.nav-link {
						color: $brandcolor1-light;
						margin: 0 !important;
						padding: 0.625em 0.625em !important;
						border-top: 5px solid rgba($brandcolor3, 0);
						&:hover {
							color: $brandcolor1;
							border-radius: 0;
							margin: 0 !important;
							padding: 0.625em !important;
						}
					}
					.active {
						border-radius: 0 !important;
						border-top: 5px solid $brandcolor3;
						@media (max-width: 991.9px) {
							border-top: none;
							border-left: 5px solid $brandcolor3;
						}
					}
				}
			}
		}
		.navbar-toggler {
			padding: 0.875em 0.875em;
			font-family: $font-nav1;
			font-size: 0.875em;
			line-height: 1;
			font-weight: 700;
			letter-spacing: .25em;
			text-transform: uppercase;
			color: $brandcolor1;
		}
		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(50,71,122,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
		}
	}
}

/* People Cards */
.people-card-container {
	padding: 0 1.25em 7.5em 1.25em;
	.embed-people-card {
		aspect-ratio: 3 / 3.6 !important;
		@media (max-width: 767.9px) {
		}
		.embed-responsive-item {
			width: auto;
		}
	}
}
.people-card {
	margin: 0.625em;
	border-radius: 0;
	width: auto;
	overflow: hidden;
	&:hover {
		& > * {
			cursor: pointer;
		}
		.card-img-overlay-hover {
			opacity: 1;
			pointer-events: initial;
		}
		.card-img-top {
			.card-img-back {
				opacity: 0.5;
				transform: scale(1.25);
				filter: blur(4px);
				-webkit-filter: blur(4px);
				-moz-filter: blur(4px);
				-ms-filter: blur(4px);
				-o-filter: blur(4px);
			}
		}
	}
	.card-img-top {
		top: initial;
		border-radius: 0;
		z-index: 0;
		width: auto !important;
		// aspect-ratio: 1 / 1 !important;
		overflow: hidden;
		.card-img-back {
			opacity: 0.75;
			filter: blur(0px);
			-webkit-filter: blur(0px);
			-moz-filter: blur(0px);
			-ms-bfilter: blur(0px);
			-o-filter: blur(0px);
			-webkit-transition: all 3.0s ease-out;
			-moz-transition: all 3.0s ease-out;
			-ms-transition: all 3.0s ease-out;
			-o-transition: all 3.0s ease-out;
			transition: all 3.0s ease-out;
		}
		.card-back-overlay {
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: 2;
			pointer-events: none;
		}
	}
	.card-body {
		border-radius: 0;
		text-align: center;
		.card-people-title {
			margin: 0;
			font-size: 1.5em;
			font-size: clamp(1.25rem, -webkit-calc(1.25rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
			font-size: clamp(1.25rem, -moz-calc(1.25rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
			font-size: clamp(1.25rem, calc(1.25rem + 0.25 * ((100vw - 36em) / 39)), 1.5rem);
			font-weight: 700;
			text-transform: initial;
			letter-spacing: 0;
			color: $brandcolor1-light;
		}
		.card-people-title-position {
			font-family: $font-body2;
			font-size: 1.125em;
			font-size: clamp(1.125rem, -webkit-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
			font-size: clamp(1.125rem, -moz-calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
			font-size: clamp(1.125rem, calc(1.125rem + 0.125 * ((100vw - 36em) / 39)), 1.25rem);
			font-weight: 400;
			font-style: italic;
			text-transform: initial;
			letter-spacing: 0;
			color: $gray-mid2;
		}
	}
}

/* Practice Area Cards */
.practice-attorney-card-container {
	margin: 0;
	padding: 0.5625em 0;
}
.practice-people-card {
	margin: 0.5626em 0;
	padding: 0;
	border-radius: 0;
	display: flex;
	align-items: center;
	&:hover {
		.card-body {
			.card-people-title {
				color: $brandcolor1-light2;
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			.card-people-title {
				color: $brandcolor1;
			}
		}
	}
	.card-img-top {
		width: 66%;
		max-width: 15.0rem;
		height: auto;
		top: initial;
		border-radius: 50%;
		overflow: hidden;
		.card-img-back {
			opacity: 0.60;
		}
		.card-back-overlay {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
			width: 66%;
			max-width: 15.0rem;
			height: auto;
			border-radius: 50%;
			overflow: hidden;
			z-index: 0;
		}
	}
	.card-body {
		border-radius: 0;
		padding: 0.5625em;
		.card-people-title {
			margin: 0;
			font-size: 1.125em;
			font-weight: 700;
			letter-spacing: 0;
			text-transform: initial;
			color: $brandcolor1-light;
		}
		.card-people-title-position {
			font-family: $font-body2;
			font-size: 1.0em;
			font-weight: 100;
			font-style: italic;
			line-height: 1;
			color: $gray-mid3;
		}
	}
}

//Articles People Thumbnail
.news-people-card {
	margin: 0.625em 1.875em 0.625em 0;
	padding: 0;
	border-radius: none !important;
	display: flex;
	align-items: center;
	max-width: 240px;
	float: left;
	@media (max-width: 767.9px) {
		margin: 0.625em auto;
		float: none;
	}
	.card-img-top {
		width: 100%;
		height: auto;
		top: initial;
		border-bottom: 6px solid $brandcolor2;
		border-radius: 0 !important;
		.card-img-back {
			opacity: 0.6;
			border-radius: 0 !important;
		}
		.card-back-overlay {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			// overflow: hidden;
			z-index: 0;
			border-radius: 0;
			border-radius: 0 !important;
		}
	}
	.card-body {
		border-radius: 0 !important;
		padding: 0.5625em;
		.card-people-title {
			font-family: $font-body1;
			font-size: 1.25em;
			font-weight: 700;
			color: $gray-dark1;
			margin: 0;
			text-transform: initial;
		}
		.card-people-title-position {
			font-size: 0.875em;
		}
	}
}

//Main People Detail Page
.people-header-container {
	position: relative;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.people-header-container-overlay-1 {
		opacity: 0.8;
		background: linear-gradient(90deg, rgba($gray-dark1,0.8) 0%, rgba($gray-dark1,0.25) 50%, rgba($gray-dark1,0.25) 75%, rgba($gray-dark1,0.8) 100%);
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.people-header-container-overlay-2 {
		opacity: 0.5;
		background: radial-gradient(circle, rgba($gray-dark1,0.25) 0%, rgba($gray-dark1,0.6) 100%);
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	.people-header-text-overlay {
		position: relative;
		z-index: 3;
		padding: 0 0.625rem;
	}
}
.people-header-info-container, .people-body-info-container {
	padding: 2.5em;
	@media (min-width: 1199.9px) {
		padding-left: 0;
	}
	.people-header-name {
		color: $white;
	}
	.people-header-title-position {
		font-family: $font-heading1;
		font-weight: 400;
		font-style: italic;
		text-transform: initial;
		letter-spacing: 0;
		color: rgba($white, 0.75);
	}
	color: $white;
	a {
		color: $white;
	}
	.people-header-divider {
		width: 6.0em;
		border-top: 1px solid $brandcolor3;
		color: $brandcolor3;
		margin-left: 0;
	}
	p {
		font-family: $font-body1;
		font-size: 1.5em;
		color: $gray-white1;
		margin-bottom: 0.625em;
		line-height: 1;
		a {
			font-family: $font-nav1;
			color: $white;
		}
	}
	.btn-sm {
		font-size: 1.0em;
		padding: 0.625em 1.25em;
	}
}
.people-body-info-container {
	padding: 2.5em;
	background-color: $gray-mid3;
}

.people-container {
	padding: 5.0em 0;
	@media (max-width: 767.9px) {
		padding: 5.0em 1.5em;
	}
	ul {
		list-style-type: none;
		margin: 0 0 2.5em 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0.625em 0;
			line-height: 1;
			border-bottom: 1px solid $gray-light1;
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				border-bottom: none;
			}
			&::marker {
				content: "";
				display: none;
			}
			b {
				color: $black;
				font-weight: 700;
			}
			&::before {
				content: "";
				display: none;
			}
		}
	}
	h3 {
		margin-bottom: 1.25rem;
		ul, li {
			position: relative;
			padding: 0;
			margin: 0;
			ul {
				margin-left: 1.5em;
				&::before {
					font-family: $font-icons1;
					content: "\f105";
					position: absolute;
					left: -0.75em;
					top: 0;
				}
			}
		}
	}
	.people-profile-logo {
		display: block;
		margin: 0;
		padding: 0;
		width: 75%;
		max-width: 20.0rem;
	}
	.people-col1-container {
		@media (min-width: 1199.9px) {
			padding-left: 0;
		}
		@media (max-width: 1199.9px) {
			padding: 2.5rem 2.5rem 5rem 2.5rem;
		}
		@media (max-width: 767.9px) {
			padding: 2.5rem 1.25rem 5rem 1.25rem;
		}
	}
}

.people-support-staff-container {
	.row {
		margin: 0;
		padding: 0.625em 0;
		border-bottom: 1px solid $gray-light1;
		&:first-child {
			// padding-top: 0;
		}
		&:last-child {
			border-bottom: none;
		}
	}
}

.people-related-news-container {
	position: relative;
	.row {
		margin: 0;
		padding: 0.625em 0;
		border-bottom: 1px solid $gray-light1;
		&:first-child {
			// padding-top: 0;
		}
		&:last-child {
			border-bottom: none;
		}
	}
}
